import {Action} from './actionCreators'

const initialState: MessagesState = {
  messages: [
    {
      timestamp: "1/1/1",
      message:
        "Hello, welcome to Nate Verlin's Professional Website",
      messageType: "Response",
    },
    {
    timestamp: "1/1/1",
    message:
        "Get started by typing your first command!",
        messageType: "Response",
    },
  ],
}

const reducer = (
    state: MessagesState = initialState,
    action: Action
  ): MessagesState => {
    switch (action.type) {
        case 'ADD_MESSAGE':
        const newMessage: IMessage = {
            timestamp: action.message.timestamp,
            message: action.message.message,
            messageType: action.message.messageType
        }
        return {
            ...state,
            messages: state.messages.concat(newMessage),
        }
        case 'ADD_MESSAGE_RESPONSE':
            const resMessage: IMessage = {
                timestamp: action.message.timestamp,
                message: action.message.message,
                messageType: action.message.messageType
            }
            return {
                ...state,
                messages: state.messages.concat(resMessage),
            }
        default: return state
    }
    
  }
  
  export default reducer