import React, {FC} from 'react';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { Dispatch } from '@reduxjs/toolkit';
import { addMessage, addResponse} from '../../../store/actionCreators';


const Chat : FC = () => {
    const dispatch: Dispatch<any> = useAppDispatch();
    const messages = useAppSelector((state) => state.messages);

    var listItems = messages.map((ms: IMessage) => {
        if (ms.messageType === "User"){
            return (
                <div className="flex justify-end flex-row m-0">
                    <div className="flex mx-2 my-4 bg-gray-300 rounded-lg w-1/2 overflow-x-auto break-all">
                        <li className="m-4">{ms.message}</li>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className='flex justify-start flex-row m-0'>
                    <div className="flex mx-2 my-4 bg-gray-100 w-1/2 rounded-lg break-all justify-start ">
                        <li className="typewriter rounded-lg m-4">{ms.message}</li>
                    </div>
                </div>
            );
        }
        });


    const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter"){
            const inputEvent = event.target as HTMLInputElement;
            if (inputEvent.value as string !== ""){
                dispatch(addMessage({message: inputEvent.value as string, timestamp: "1/1/1", messageType: "User"}));
                dispatch(addResponse());
                inputEvent.value = "";
            }

        }
    }

  return (
    <>
        <div className="flex flex-col-reverse space-y-1 overflow-y-hidden h-full hover:overflow-y-auto ">
            <ol className='overflow-y-hidden'>{listItems}</ol>
        </div>
        <div className="flex flex-col mx-2 mt-auto mb-4 bg-white rounded-lg">
                <hr className="bg-gray-300 w-full mt-auto mb-auto h-0.5"/>
                <input type="text" onKeyDown={keyPressHandler} className="
                    form-control
                    px-3
                    mt-3
                    w-full
                    text-gray-700
                    bg-white
                    border border-solid border-gray-300
                    rounded-lg
                    transition
                    ease-in-out
                    focus:text-gray-700 focus:bg-white focus:border-black focus:outline-none"  
                    placeholder='Start typing here...then press enter'
                />
            </div>
    </>
  );
};

export default Chat;
