import React, { FC } from 'react';
import Chat from './chat/chat';

const Console : FC = () => {
  return (
    <>
      <div className="flex items-stretch flex-row grow mb-4 shadow-lg bg-gray-50 max-h-full">
        <div className="flex flex-col w-1/4 mx-20 my-40" >
            <h1 className="font-light text-2xl text-black text-left font-medium">Start Here</h1>
            <h2 className="font-light text-m text-black text-left font-light">Type any of the following commands into the terminal and press enter:</h2>
            <div className='flex flex-row'>
              <button className='my-3 mx-3'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
              </button>
              <button className='my-3 mx-3'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
              </button>
            </div>
        </div>
        <div className="w-3/4 text-l text-black bg-white rounded-xl m-20 flex flex-col overflow-x-hidden">
          <Chat />
        </div>
      </div>
    </>
  );
};

export default Console;