
//action def
export interface AddMessageAction {
    type: 'ADD_MESSAGE'
    message: IMessage
}

export interface AddMessageResponseAction {
    type: 'ADD_MESSAGE_RESPONSE'
    message: IMessage
}

//union action types
export type Action = AddMessageAction | AddMessageResponseAction;

//action creator
export const addMessage = (message: IMessage): AddMessageAction => {
    return { type: 'ADD_MESSAGE', message }
}

export const addResponse = (): AddMessageResponseAction => {
    const response = simulateHttpRequest();
    return { type: 'ADD_MESSAGE_RESPONSE', message: response }
}


function simulateHttpRequest(): IMessage {
    return  {message: 'Hey hsfjk sdhjfkl sdh fhdsjk flha hfjdska fhljdkl  f dshjk dfsh jk hjfs ksfhj kdshfjkhsd jfkshd ksjfhj sdk hfdjsalhdjf hjfak lhd jkfl', timestamp: "1/1/1", messageType: "Response"} as IMessage
}