import React, { FC } from 'react';

interface HeaderProps {
  title: string;
}

const Header : FC<HeaderProps> = ({ title }) => {
  return (
    <>
      <div className="flex w-full p-10 mx-auto bg-white shadow-lg flex items-center space-x-4">
        <div>
          <div className="text-3xl text-black font-bold">{title}</div>
        </div>
      </div>
    </>
  );
};

export default Header;