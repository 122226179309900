import React from 'react';
import Header from './components/Header';
import Console from './components/console/Console';
import './App.css';


function App() {
  return (
    <div className="App flex flex-col min-h-screen">
      <Header title='Nate Verlin - Software Developer'/>
      <Console></Console>
    </div>
  );
}

export default App;
